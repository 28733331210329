import Image from 'next/image'
import BennyBoy from 'src/lib/images/bennyboy.png'
const IndexRoute = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'flex-start',
        alignItems: 'end',
      }}
    >
      <Image src={BennyBoy} placeholder='blur' />
    </div>
  )
}

export default IndexRoute
